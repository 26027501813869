import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Socail from "./social"

const Header = () => (
  <header>
    <div className="max-w-7xl mx-auto container">
      <div className="py-2 flex flex-col lg:flex-row justify-between items-center font-jost px-4 lg:px-0">
        <div className="flex lg:space-x-8 lg:py-0 py-2 justify-between items-center">
          <Link to="/">
            <img
              src="https://cdn0.iconfinder.com/data/icons/insecticide-ii/524/insecticide-color-15-512.png"
              alt=""
              className="h-10 w-10 lg:block hidden"
            />
          </Link>
          <div className="block">
            <div className="lg:text-2xl font-medium hidden lg:block">
              КФП-экология
            </div>
            <div className="lg:hidden text-lg font-semibold">
              Красильников, Фролов и партнеры
            </div>
            <div className="text-xs hidden lg:block text-gray-400">
              Группа компаний КФП
            </div>
          </div>
        </div>
        <div className="lg:block hidden">
          <h1 className="font-raleway text-sm italic">
            Обеспечение экологической безопасности бизнеса и населения
          </h1>
        </div>
        <div className="flex space-x-4">
          <div className="py-4">
            <Socail />
          </div>
          <div className="flex flex-col place-content-end">
            <div className="float-right font-semibold text-xl">
              <a href="tel:+7 (991) 190-60-52">+7 (991) 190-60-52</a>
            </div>
            <div className="text-zinc-600 text-sm">
              Группа компаний +7 (987) 290-07-27
            </div>
            <div className="text-gray-400 text-xs float-right">
              пн-вс - с 9:00 до 21:00
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-stone-200 py-3">
      <div className="lg:max-w-7xl mx-auto container">
        <div className="lg:flex justify-between font-raleway px-5 lg:px-0">
          <div className="lg:flex justify-start items-center lg:space-x-8 font-medium grid grid-cols-3 gap-2 text-sm lg:text-base">
            <Link
              to="/"
              className="hover:underline hover:decoration-2 hover:decoration-sky-700"
            >
              <div>Главная</div>
            </Link>
            <Link
              to="/uslugi"
              className="hover:underline hover:decoration-2 hover:decoration-sky-700"
            >
              <div>Услуги</div>
            </Link>
            <Link
              to="/drugs"
              className="hover:underline hover:decoration-2 hover:decoration-sky-700"
            >
              <div>Препараты</div>
            </Link>
            <Link
              to="/price"
              className="hover:underline hover:decoration-2 hover:decoration-sky-700"
            >
              <div>Прайс-лист</div>
            </Link>
            <Link
              to="/orgs"
              className="hover:underline hover:decoration-2 hover:decoration-sky-700"
            >
              <div>Партнеры</div>
            </Link>
            <Link
              to="/blog"
              className="hover:underline hover:decoration-2 hover:decoration-sky-700"
            >
              <div>Статьи</div>
            </Link>
            <Link
              to="/otzyv"
              className="hover:underline hover:decoration-2 hover:decoration-sky-700"
            >
              <div>Отзывы</div>
            </Link>
            <Link
              to="/contacts"
              className="hover:underline hover:decoration-2 hover:decoration-sky-700"
            >
              <div>Контакты</div>
            </Link>
          </div>
          <div className="p-2 hidden rounded-md text-sm bg-amber-300 hover:bg-gradient-to-r from-amber-400 hover:to-pink-400">
            Заказть звонок
          </div>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
