import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation } from "swiper"
import Layout from "../components/layout"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

import deklar1 from "../images/deklar-1.jpg"
import deklar2 from "../images/deklar-2.jpg"
import deklar3 from "../images/deklar-3.jpg"
import deklar4 from "../images/deklar-4.jpg"
import deklar5 from "../images/deklar-5.jpg"
import deklar6 from "../images/deklar-6.jpg"
import deklar7 from "../images/deklar-7.jpg"
import deklar8 from "../images/deklar-8.jpg"
import deklar9 from "../images/deklar-9.jpg"
import deklar10 from "../images/deklar-10.jpg"
import deklar11 from "../images/deklar-11.jpg"
import deklar12 from "../images/deklar-12.jpg"
import deklar13 from "../images/deklar-13.jpg"
import deklar14 from "../images/deklar-14.jpg"

const deklaration = [
  deklar10,
  deklar11,
  deklar12,
  deklar13,
  deklar14,
  deklar1,
  deklar2,
  deklar3,
  deklar4,
  deklar5,
  deklar6,
  deklar7,
  deklar8,
  deklar9,
]
export default function Drugs() {
  return (
    <Layout>
      <div className="md:pt-16 pt-6 flex md:space-x-16 px-4 lg:px-0">
        <div className="space-y-5">
          <div className="flex justify-between items-center">
            <div className="w-2/3">
              <h1 className="lg:text-2xl text-xl font-bold">
                Используем проверенные и только сертифицированные препараты
                импортного и отечественного производства
              </h1>
              <div className="lg:block hidden py-5">
                <div className="lg:text-xl font-serif italic">
                  Наши специалисты используют только сертифицированные
                  препараты. Для большей эффективности, с учетом ситуации на
                  конкретном объекте, используется не один препарат, а их
                  комбинация, что позволяет добиваться положительного результата
                  уже после первой обработки. В отдельных, наиболее сложных
                  случаях или в силу требований законодательства приходится
                  проводить повторную или периодическую обработку, причем с
                  заменой комбинации препаратов с тем, чтобы у вредных насекомых
                  или грызунов не вырабатывался иммунитет на активное вещество
                  <p className="py-4 text-base text-gray-500">
                    С уважением, команда <br />
                    &laquo;Красильников, Фролов и партнеры&raquo;
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3">
              <StaticImage
                src="../images/thx.png"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Красильников, Фролов и партнеры"
              />
            </div>
          </div>
          <div className="block lg:hidden py-4">
            <div className="lg:text-xl font-serif italic">
              В ближайшее время с вами свяжется наш специалист и
              проконсультирует вас по всем вопросам!
              <p className="py-4 text-base text-gray-500">
                С уважением, команда <br />
                &laquo;Красильников, Фролов и партнеры&raquo;
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10">
        <Swiper
          slidesPerView={6}
          spaceBetween={5}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination, Navigation]}
        >
          {deklaration.map(it => (
            <SwiperSlide key={it}>
              <div className="border">
                <img
                  src={it}
                  alt="Красильников, Фролов и партнеры"
                  title="Красильников, Фролов и партнеры"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Layout>
  )
}
