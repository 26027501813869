import React from "react"

export const social = [
  {
    title: "VK",
    logo: "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_vkontakte-1024.png",
    link: "https://vk.com/kfpartners",
  },
]

export default function Socail() {
  return (
    <div className="flex space-x-6">
      {social.map(({ logo, title, link }) => {
        return (
          <div key={title}>
            <a href={link} target="_blank" rel="noreferrer">
              <img className="w-8 h-8" alt={title} src={logo} />
            </a>
          </div>
        )
      })}
    </div>
  )
}
