import React from "react"
import Socail from "./social"

export const social = [
  {
    title: "VK",
    logo: "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_vkontakte-1024.png",
    link: "https://vk.com/kfpartners",
  },
]

export default function Footer() {
  return (
    <div className="max-w-7xl mx-auto container text-white font-raleway">
      <div className="grid md:grid-cols-3 gap-10 px-5 lg:px-0">
        <div>
          <div className="font-semibold text-xl">
            ООО "КФП &ndash; экология"
          </div>
          <div className="py-2 text-gray-400">
            ИНН 1655380228 КПП 165501001
            <br /> ОГРН 1171690008676
          </div>
          <div className="py-4 text-gray-400">
            Экологическая защита организаций – дезинфекция, дезинсекция,
            дератизация объектов.
          </div>
          <div className="py-4 text-gray-400 hover:underline">
            <a href="https://kfpartners.ru">
              Группа компаний ООО &laquo;КФП&raquo;
            </a>
          </div>
        </div>
        <div className="text-gray-300">
          <div className="font-semibold text-lg text-white">Наши контакты</div>
          <div>
            <span className="text-gray-500">График работы: </span>Пн-Пт: 09
            <sup>00</sup> – 18<sup>00</sup>
          </div>
          <div className="py-4">
            <span className="text-gray-500">Адрес: </span>420021, г. Казань ул.
            Габдуллы Тукая, д. 23, офис 213
          </div>
          <div className="pb-4">
            <p>
              <span className="text-gray-500">Заказать услугу: </span>+7 (991) 190-60-52
            </p>
            <p>
              <span className="text-gray-500">Группа компаний: </span>
              +7 (987) 290-07-27
            </p>
          </div>
          <div>
            <span className="text-gray-500">e-mail: </span>info@kfpartners.ru
          </div>
        </div>
        <div className="space-y-4">
          <h4>Мы в социальных сетях</h4>
          <Socail />
        </div>
      </div>
    </div>
  )
}
